var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import axios from "axios";
let nightlogin = class nightlogin extends Vue {
    prename = '';
    lastname = '';
    dialog = false;
    loading = false;
    User = [];
    translation = {};
    headers = [
        { text: 'Vorname', align: 'start', sortable: false, value: 'PreName' },
        { text: 'Nachname', sortable: false, value: 'LastName', },
        { text: 'Türcode', sortable: false, value: 'Pin' },
        { text: 'Zimmernummer', sortable: false, value: 'RoomNumber' },
        { text: 'Gültig bis', sortable: false, value: 'DateofExpiry' },
        //   {text: 'Auschecken', sortable: true, value: 'actions'},
    ];
    options = {
        itemsPerPage: 20,
        page: 1,
        total: 0
    };
    page = {};
    snackbar = false;
    text = "";
    itemDelete(id) {
        if (confirm("Do you really want to delete?")) {
            axios.delete('/api/customer/' + id);
            this.options.itemsPerPage = 19;
            this.options.itemsPerPage = 20;
        }
    }
    login() {
        this.loading = true;
        axios.post('/nightlogin', { PreName: this.prename, LastName: this.lastname }).then((response) => {
            if (response.status == 200) {
                this.User[0] = response.data.data;
                this.dialog = true;
                this.loading = false;
                this.snackbar = false;
            }
        }).catch((error) => {
            this.User = [];
            this.text = "Der eingegebene Gast Exestiert nicht";
            this.loading = false;
            this.snackbar = true;
        });
    }
    SnackBarClose() {
        this.snackbar = false;
        this.User = [];
    }
    userHasRole(role) {
        return this.$store.getters.userHasRole(role);
    }
    watchLang() {
        if (this.$vuetify.lang.current == 'de') {
            this.translation = {
                Vorname: 'Vorname',
                Nachname: 'Nachname',
                login: 'Einloggen'
            };
            this.headers = [
                { text: 'Vorname', align: 'start', sortable: false, value: 'PreName' },
                { text: 'Nachname', sortable: false, value: 'LastName', },
                { text: 'Türcode', sortable: false, value: 'Pin' },
                { text: 'Zimmernummer', sortable: false, value: 'RoomNumber' },
                { text: 'Gültig bis', sortable: false, value: 'DateofExpiry' },
                //   {text: 'Auschecken', sortable: true, value: 'actions'},
            ];
        }
        else {
            this.translation = {
                Vorname: 'pre name',
                Nachname: 'last name',
                login: 'Login'
            };
            this.headers = [
                { text: 'pre name', align: 'start', sortable: false, value: 'PreName' },
                { text: 'last name', sortable: false, value: 'LastName', },
                { text: 'door code', sortable: false, value: 'Pin' },
                { text: 'room number', sortable: false, value: 'RoomNumber' },
                { text: 'vlaid until', sortable: false, value: 'DateofExpiry' },
                //   {text: 'Auschecken', sortable: true, value: 'actions'},
            ];
        }
    }
    created() {
        this.watchLang();
        this.$store.state.monitor = false;
    }
    mounted() {
        this.watchLang();
    }
};
__decorate([
    Watch('$vuetify.lang.current', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], nightlogin.prototype, "watchLang", null);
nightlogin = __decorate([
    Component({
        name: 'nightlogin'
    })
], nightlogin);
export default nightlogin;
