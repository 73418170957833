var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import Vue from 'vue';
import { Component, Watch } from "vue-property-decorator";
import axios from "axios";
let CreateCustomer = class CreateCustomer extends Vue {
    customer = {
        DateofExpiry: "",
        TimeofExpiry: "",
    };
    translation = {};
    User = {};
    snackbar = false;
    text = "";
    create() {
        axios.put('/api/customer', { customer: this.customer }).then((response) => {
            if (response.status == 201) {
                this.$router.push('/customerlist');
            }
        }).catch((error) => {
            this.text = error.response.data.message + '\n \r' + ": Ein Gast mit dem selben Vor und Nachname ist bereits ein Zimmer zugeteilt.";
            this.snackbar = true;
        });
        this.snackbar = false;
    }
    watchLang() {
        if (this.$vuetify.lang.current == 'de') {
            this.translation = {
                Vorname: 'Gast Vorname',
                Nachname: 'Gast Nachname',
                code: 'Gast Pin',
                number: 'Gast Raum Nummer',
                date: 'Ablaufdatum',
                create: 'Erstellen'
            };
        }
        else {
            this.translation = {
                Vorname: 'guest pre name',
                Nachname: 'guest last name',
                code: 'guest Pin',
                number: 'guest Raum Nummer',
                date: 'valid until',
                create: 'create'
            };
        }
    }
    mounted() {
        this.watchLang();
        if (this.$store.getters.isLoggedIn) {
            this.getRoles;
            this.$store.state.monitor = false;
        }
        else {
            this.$router.push('/login');
        }
    }
    get getRoles() {
        axios.get('/api/users').then((response) => {
            this.User = response.data.data;
        });
        return this.User;
    }
};
__decorate([
    Watch('$vuetify.lang.current', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], CreateCustomer.prototype, "watchLang", null);
CreateCustomer = __decorate([
    Component({
        name: "CreateCustomer",
    })
], CreateCustomer);
export default CreateCustomer;
