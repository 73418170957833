import axios from "axios";
// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
export default {
    install: (Vue, options) => {
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        //Axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';
        axios.defaults.withCredentials = true;
        Vue.prototype.$http = axios;
    }
};
export class AxiosPluginOptions {
}
