var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import Login from "./components/auth/Login.vue";
import Logout from "./components/auth/Logout.vue";
import Navigation from "./components/navigation/Navigation.vue";
import LanguageSwitcher from "./components/LanguageSwitcher.vue";
let App = class App extends Vue {
    constructor() {
        super();
        if (this.$store.state.user != null) {
            this.$router.push('/login');
        }
    }
    drawer = false;
    url = "https://eurostar-hotel.de/wp-content/uploads/2020/02/Logo-Eurostar_2013_4.png";
    show = false;
    translation = {
        modus: ''
    };
    switcher() {
        if (!this.$vuetify.theme.dark) {
            this.url = "https://eurostar-hotel.de/wp-content/uploads/2020/02/Logo-Eurostar_2013_4.png";
            return this.$vuetify.theme.dark = true;
        }
        else {
            this.url = "https://eurostar-hotel.de/wp-content/uploads/2020/02/Logo-Eurostar_2013_4.png";
            return this.$vuetify.theme.dark = false;
        }
    }
    watchLang() {
        if (this.$vuetify.lang.current == 'de') {
            this.translation = {
                mode: 'Dunkler Modus'
            };
        }
        else {
            this.translation = {
                mode: 'Dark Mode'
            };
        }
    }
    mounted() {
        this.watchLang();
        this.$store.commit('user');
    }
};
__decorate([
    Watch('$vuetify.lang.current', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], App.prototype, "watchLang", null);
App = __decorate([
    Component({
        name: 'App',
        components: { LanguageSwitcher, Navigation, Login, Logout }
    }),
    __metadata("design:paramtypes", [])
], App);
export default App;
