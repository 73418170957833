import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios";
import router from '../router/Router';
import store from '../store/Store';
Vue.use(Vuex);
export const Auth = {
    login(email, password) {
        axios.get("/sanctum/csrf-cookie").then((_) => {
            axios.post('/login', { email: email, password: password }).then((response) => {
                store.commit('user');
                setTimeout(() => {
                    if (store.getters.userHasRole('Arbeitschritt abschließen')) {
                        router.push("/job/worklogs/");
                    }
                    else {
                        router.push("/dashboard");
                    }
                }, 500);
            });
        });
    },
    logout() {
        axios.get("/sanctum/csrf-cookie").then((_) => {
            axios.post('/logout').then((response) => {
                store.state.user = null;
            }).finally(() => {
                setTimeout(() => {
                    router.push("/");
                }, 500);
            });
        });
    },
    user() {
        axios.get('/api/user').then((response) => {
            if (response.status === 200) {
                store.state.user = response.data.data;
            }
            else {
                store.state.user = null;
            }
        });
    }
};
export default Auth;
