var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import Vue from 'vue';
import { Component, Watch } from "vue-property-decorator";
import axios from "axios";
let UserList = class UserList extends Vue {
    search = '';
    users = [];
    page = [];
    text = '';
    snackbar = false;
    timeout = 5000;
    loading = true;
    translation = {};
    headers = [
        {
            text: 'User',
            align: 'start',
            sortable: false,
            value: 'name',
        },
        { text: 'E-Mail', value: 'email' },
        { text: 'Role', value: 'role.0.name' },
        { text: 'Aktionen', value: 'actions' },
    ];
    get getUserList() {
        this.loading = true;
        axios.get('/api/users').then((response) => {
            this.users = response.data.data;
            this.page = response.data.meta;
            this.loading = false;
        });
        return this.users;
    }
    UserDelete(id) {
        if (confirm("Do you really want to delete?")) {
            axios.delete('/api/users/' + id).then(() => {
                this.getUserList;
            });
            this.text = "Der User wurde erfolgreich gelöscht";
            this.snackbar = true;
        }
    }
    filterOnlyLowerText(value, search, item) {
        return value != null &&
            search != null &&
            typeof value === 'string' &&
            value.toString().toLocaleLowerCase().indexOf(search) !== -1;
    }
    userHasRole(role) {
        return this.$store.getters.userHasRole(role);
    }
    watchLang() {
        if (this.$vuetify.lang.current == 'de') {
            this.headers = [
                {
                    text: 'Benutzer',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                { text: 'E-Mail', value: 'email' },
                { text: 'Role', value: 'role.0.name' },
                { text: 'Aktionen', value: 'actions' },
            ];
            this.translation = {
                itemsPerPageText: 'Benutzer pro Seite',
                itemsPerPageAllText: 'Alle'
            };
        }
        else {
            this.headers = [
                {
                    text: 'User',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                { text: 'E-Mail', value: 'email' },
                { text: 'Role', value: 'role.0.name' },
                { text: 'actions', value: 'actions' },
            ];
            this.translation = {
                itemsPerPageText: 'user per side',
                itemsPerPageAllText: 'all'
            };
        }
    }
    mounted() {
        if (this.$store.getters.isLoggedIn) {
            this.getUserList;
            this.$store.state.monitor = false;
        }
        else {
            this.$router.push('/login');
        }
    }
};
__decorate([
    Watch('$vuetify.lang.current', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], UserList.prototype, "watchLang", null);
UserList = __decorate([
    Component({
        name: "UserList",
    })
], UserList);
export default UserList;
