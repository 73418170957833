var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
let Navigation = class Navigation extends Vue {
    translation = {};
    items = [
        { title: 'Dashboard', icon: 'mdi-view-dashboard', link: "dashboard" },
        { title: 'Monitor', icon: 'mdi-image', link: "monitor", target: "_blank" },
        { title: 'Benutzer Verwaltung', icon: 'mdi-help-box', link: "$router.push(`/userlist`)" },
    ];
    logout() {
        this.$store.commit('logout');
    }
    userHasRole(role) {
        return this.$store.getters.userHasRole(role);
    }
    openMonitoring() {
        let routeData = this.$router.resolve({ name: 'Monitoring' });
        window.open(routeData.href, '_blank');
    }
    openMonitor() {
        let routeData = this.$router.resolve({ name: 'Monitor' });
        window.open(routeData.href, '_blank');
    }
    watchLang() {
        if (this.$vuetify.lang.current == 'de') {
            this.translation = {
                dashboard: 'Dashboard',
                customer_managment: 'Gästeverwaltung',
                user_managment: 'Benutzerverwaltung',
                logout: 'Ausloggen'
            };
        }
        else {
            this.translation = {
                dashboard: 'Dashboard',
                customer_managment: 'Customer managment',
                user_managment: 'User managment',
                logout: 'logout'
            };
        }
    }
    mounted() {
        this.watchLang();
    }
    created() {
        this.watchLang();
    }
};
__decorate([
    Watch('$vuetify.lang.current', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], Navigation.prototype, "watchLang", null);
Navigation = __decorate([
    Component({
        name: 'Navigation'
    })
], Navigation);
export default Navigation;
