var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import axios from "axios";
let Dashboard = class Dashboard extends Vue {
    constructor() {
        super();
        if (!this.$store.getters.isLoggedIn) {
            this.$router.push('/login');
        }
    }
    options = {
        itemsPerPage: 20,
        page: 1,
        total: 0
    };
    page = {};
    translation = {};
    loading = false;
    Customer = [];
    headers = [];
    getCustomer() {
        axios.get('/api/customer/' + this.$store.getters.getUser.id, {
            params: {
                itemsPerPage: this.options.itemsPerPage,
                page: this.options.page,
            }
        }).then((response) => {
            this.Customer = response.data.data;
            this.page = response.data.meta;
        });
    }
    itemDelete(id) {
        if (confirm("Do you really want to delete?")) {
            axios.delete('/api/customer/' + id);
            this.options.itemsPerPage = 19;
            this.options.itemsPerPage = 20;
        }
    }
    userHasRole(role) {
        return this.$store.getters.userHasRole(role);
    }
    watchOptions() {
        this.getCustomer();
    }
    watchLang() {
        if (this.$vuetify.lang.current == 'de') {
            this.headers = [
                { text: 'Vorname', align: 'start', sortable: true, value: 'PreName' },
                { text: 'Nachname', sortable: true, value: 'LastName', },
                { text: 'Pin', sortable: true, value: 'Pin' },
                { text: 'Gültig bis', sortable: true, value: 'DateofExpiry' },
                { text: 'löschen', sortable: true, value: 'actions' },
            ];
            this.translation = {
                itemsPerPageText: 'Zimmer pro Seite',
                itemsPerPageAllText: 'Alle'
            };
        }
        else {
            this.headers = [
                { text: 'pre name', align: 'start', sortable: true, value: 'PreName' },
                { text: 'last name', sortable: true, value: 'LastName', },
                { text: 'Pin', sortable: true, value: 'Pin' },
                { text: 'valid until', sortable: true, value: 'DateofExpiry' },
                { text: 'delete', sortable: true, value: 'actions' },
            ];
            this.translation = {
                itemsPerPageText: 'rooms per side',
                itemsPerPageAllText: 'all'
            };
        }
    }
    mounted() {
        this.watchLang();
        if (this.$store.getters.isLoggedIn) {
            this.getCustomer();
            this.$store.state.monitor = false;
        }
        else {
            this.$store.commit('logout');
        }
    }
};
__decorate([
    Watch('options', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], Dashboard.prototype, "watchOptions", null);
__decorate([
    Watch('$vuetify.lang.current', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], Dashboard.prototype, "watchLang", null);
Dashboard = __decorate([
    Component({
        name: 'Dashboard',
    }),
    __metadata("design:paramtypes", [])
], Dashboard);
export default Dashboard;
