import Vue from "vue"
import Axios from "./plugins/Axios";
import vuetify from './plugins/Vuetify';
Vue.use(Axios);
Vue.use(vuetify);
import App from './App.vue';
import store from "./store/Store";
import router from './router/Router';


Vue.component('app', App);

const app = new Vue({
    el: '#app',
    vuetify,
    store,
    router
})

