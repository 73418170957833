import UserList from '../components/user/UserList'; // @ts-ignore
import CustomerList from '../components/customer/CustomerList'; // @ts-ignore
import Login from '../components/auth/Login'; // @ts-ignore
import CreateUser from '../components/user/CreateUser'; // @ts-ignore
import EditUser from '../components/user/EditUser'; // @ts-ignore
import CreateCustomer from '../components/customer/CreateCustomer'; // @ts-ignore
import Dashboard from '../components/Dashboard'; // @ts-ignore
import error from '../components/error/404'; // @ts-ignore
import nightlogin from '../components/auth/nightlogin'; // @ts-ignore
import nothing from '../components/error/Nothing';
import store from '../store/Store';
const routes = [
    {
        path: '/login',
        component: Login
    },
    {
        path: '/',
        component: nothing,
    }, {
        path: '/404',
        component: error,
    },
    {
        path: '/nightlogin',
        component: nightlogin,
    },
    {
        path: '/userlist',
        component: UserList,
        beforeEnter: (to, from, next) => {
            // reject the navigation
            setTimeout(() => {
                if (store.getters.userHasRole('Superadmin')) {
                    next();
                }
                else {
                    return next('/404');
                }
            }, 500);
        },
    },
    {
        path: '/customerlist',
        component: CustomerList,
        beforeEnter: (to, from, next) => {
            // reject the navigation
            setTimeout(() => {
                if (store.getters.userHasRole('Superadmin') || store.getters.userHasRole('Admin')) {
                    next();
                }
                else {
                    return next('/404');
                }
            }, 500);
        },
    },
    {
        path: '/customer/create',
        component: CreateCustomer,
        beforeEnter: (to, from, next) => {
            // reject the navigation
            setTimeout(() => {
                if (store.getters.userHasRole('Superadmin') || store.getters.userHasRole('Admin')) {
                    next();
                }
                else {
                    return next('/404');
                }
            }, 500);
        },
    },
    {
        path: '/user/create',
        component: CreateUser,
        beforeEnter: (to, from, next) => {
            // reject the navigation
            setTimeout(() => {
                if (store.getters.userHasRole('Superadmin')) {
                    next();
                }
                else {
                    return next('/404');
                }
            }, 500);
        },
    },
    {
        path: '/users/:id/edit',
        component: EditUser,
        beforeEnter: (to, from, next) => {
            // reject the navigation
            setTimeout(() => {
                if (store.getters.userHasRole('Superadmin')) {
                    next();
                }
                else {
                    return next('/404');
                }
            }, 500);
        },
    },
    {
        path: '/dashboard',
        component: Dashboard,
        beforeEnter: (to, from, next) => {
            // reject the navigation
            setTimeout(() => {
                if (store.getters.userHasRole('Superadmin') || store.getters.userHasRole('Admin') || store.getters.userHasRole('Benutzer')) {
                    next();
                }
                else {
                    return next('/404');
                }
            }, 500);
        },
    },
];
export default routes;
