import Vue from 'vue'
import Vuex from 'vuex'
import Auth from "../plugins/Auth";
import axios from "axios";

Vue.use(Vuex)

const Store = new Vuex.Store({
    state: {
        user: null,
        monitor:false,
    },
    mutations: {
        login(state, payload) {

        Auth.login(payload.email, payload.password)

        },

        logout() {
            Auth.logout();
        },
        user() {
            Auth.user();
        },
        monitor() {
             this.state.monitor=true;
        },
    },
    getters: {
        getUser: state => {
            return state.user;
        },
        isLoggedIn: state => {
            return !!state.user;
        },
        userHasRole: state => (role) => {
            if (state.user) {
                for (let user_role of state.user.role) {
                    if (user_role.name === role) {
                        return true;
                    }
                }
            }
            return false;
        },
        isMonitor: state => {
            return state.monitor;
        },
        isSnackbar:state=>{
            return state.snackbar;
        }
    }
});

export default Store;
