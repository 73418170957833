var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
let Login = class Login extends Vue {
    password = '';
    email = '';
    translation = [];
    login() {
        this.$store.commit('login', { email: this.email.toLowerCase(), password: this.password });
    }
    watchLang() {
        if (this.$vuetify.lang.current == 'de') {
            this.translation = {
                email: 'E-Mail',
                passwort: 'Passwort',
                login: 'einloggen'
            };
        }
        else {
            this.translation = {
                email: 'E-Mail',
                passwort: 'Password',
                login: 'login'
            };
        }
    }
    userHasRole(role) {
        return this.$store.getters.userHasRole(role);
    }
    created() {
        this.watchLang();
        this.$store.state.monitor = false;
    }
};
__decorate([
    Watch('$vuetify.lang.current', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], Login.prototype, "watchLang", null);
Login = __decorate([
    Component({
        name: 'Login'
    })
], Login);
export default Login;
