var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import Vue from 'vue';
import { Component, Watch } from "vue-property-decorator";
import axios from "axios";
let CreateUser = class CreateUser extends Vue {
    user = {};
    roles = {};
    translation = {
        name: '',
        email: '',
        password: '',
        role: '',
        create: ''
    };
    create() {
        axios.post('/api/users', { user: this.user }).then((response) => {
            if (response.status == 201) {
                this.$router.push('/userlist');
            }
        });
    }
    watchLang() {
        if (this.$vuetify.lang.current == 'de') {
            this.translation = {
                name: 'Benutzer name',
                email: 'Benutzer E-mail',
                password: 'Benutzer Passwort',
                role: 'Benutzer Rolle',
                create: 'Erstellen'
            };
        }
        else {
            this.translation = {
                name: 'User name',
                email: 'User E-mail',
                password: 'User Password',
                role: 'User Role',
                create: 'create'
            };
        }
    }
    get getRoles() {
        axios.get('/api/roles').then((response) => {
            this.roles = response.data.data;
        });
        return this.roles;
    }
    mounted() {
        this.watchLang();
        if (this.$store.getters.isLoggedIn) {
            this.getRoles;
            this.$store.state.monitor = false;
        }
        else {
            this.$router.push('/login');
        }
    }
};
__decorate([
    Watch('$vuetify.lang.current', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], CreateUser.prototype, "watchLang", null);
CreateUser = __decorate([
    Component({
        name: "CreateUser",
    })
], CreateUser);
export default CreateUser;
